.boxIconCard {
    width: 200px;
    height: 100px;
    border-radius: 10px;
    position: relative;
}

.topIconBox {
    width: 200px;
    height: 60px;
}

.iconBox {
    width: 60px;
    height: 70px;
    margin-top: -25px;
    border-radius: 4px;
}

.iconBoxWarning {
    background-color: #FEA421;
}

.iconBoxSuccess {
    background-color: #5DB461;
}

.iconBoxError {
    background-color: #EC4C49;
}

.iconBoxInfo {
    background-color: #1BBED3;
}

.textInfo {
    color: #fff;
    font-size: 35px;
    font-weight: bold;
}

.textOption {
    color: #fff;
    font-size: 25px;
}