.borderOutlineBtn {
    border: solid 1px #D2D6DC !important;
    border-radius: 7px;
    text-align: left !important;
    padding: 8px;
}

button:focus{
    outline: none  !important;
    border: none !important;
}