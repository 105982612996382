.input{
    border: none;
    background-color: transparent;
    width: 100%;
    height: 30px;
    color: #000;
}

/* .iconBox{
    padding: 3px;
    font-size: 20px;
} */

.inputBox{
    border-radius: 30px;
    border: solid #E3E3E3 1px;
    /* background-color: #42B0FE; */
    /* background-color: rgba(44, 168, 255,0.1);  */
    background-color: transparent;
    width: 100%;
}

.inputBoxOnFocus {
  border: solid #2CA8FF 1px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #E3E3E3;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #E3E3E3;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #E3E3E3;
  }

  .borderOutline {
    border: solid 1px #D2D6DC !important;
    border-radius: 7px;
    text-align: left !important;
}

button:focus{
    outline: none  !important;
    border: none !important;
}