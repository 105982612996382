/* .over {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
} */

@keyframes rotationAnim {
    0% {
        transform: rotateY(0deg);
        border: none;
    }

    30% {
        transform: rotateY(90deg);
        border: none;
    }

    50% {
        transform: rotateY(180deg);
        /* border-top: solid #199EB8 1px; */
        border-bottom: none;
    }

    70% {
        transform: rotateY(270deg);
        border: none;
    }

    100% {
        transform: rotateY(360deg);
        border-top: none;
        /* border-bottom: solid #199EB8 1px; */
    }
}

.imageLoading {
    max-width: 150px;
    position: absolute;
    animation: rotationAnim 2s infinite;
}

/* .imageLoading {
    background-image: url('../../assets/images/loading2.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    position: absolute;
    /* background-color: tomato; * /
    /* border: solid #fff 2px; * /
} */