.loginBackground {
    background-image: url('../../imgs/login.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.boxBackground {
    /* background-color: rgba(44, 168, 255, 0.1); */
    border-radius: 10px;
}