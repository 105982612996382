.labelTask {
    font-weight: bold;
}

.labelTaskTitle {
    font-size: 1.2em;
    font-weight: bold;
    color: #2CA8FF;
}

.photo{
    max-width: 280px;
}

.pagePDF {
    flexDirection: 'row';
    backgroundColor: '#E4E4E4'
  }

.sectionPDF {
    margin: 10;
    padding: 10;
    flexGrow: 1
  }