.bkHelpDesk {
    /* background-color: tomato; */
    /* background-image: linear-gradient(to bottom, #8497A3, #E8A093); */
    background-image: linear-gradient(to bottom, #8497A3, #2CA8FF);
}

.boxHelpDesk{
    background-color: #fff;
    border-radius: 15px;
}

.helpDeskOption {
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 12px;
}

.helpDeskOptionActive {
    /* background-color: #2CA8FF; */
    background-image: linear-gradient(90deg, #2CA8FF, #5B7DFB);
    padding: 10px;
    color: #fff;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.5);
    border-radius: 10px;
}

.selectOptionsBox {
    width: 100%;
    border: 0;
    outline: 0;
    padding: 0.5rem 0;
    border-bottom: 2px solid #d3d3d3;
    box-shadow: none;
    color: #111;
}

.boxSelect {
    position: relative;
}

.labelSelect {
    position: absolute;
    top: 5px;
    left: 0;
    width: 100%;
    color: #d3d3d3;
    transition: 0.2s all;
    cursor: text;
}

.selectOption {
    color: #d3d3d3;
}

.roundedOutlineRedBtn {
    background-color: none;
    border: solid #A2250A 1px;
    border-radius: 100%;
    color: #A2250A;
    padding: 2px;
    width: 18px;
    height: 18px;
    font-size: 15px;
    margin: 2px;
}

.roundedOutlineRedBtn:hover {
    border: solid #fff 1px;
    background-color: #A2250A;
    color: #fff;
    padding: 12px;
}