html, body {
	background-color: #FFF !important;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-size: 12pt;
	font-family: "Roboto", -apple-system, "San Francisco", "Segoe UI", "Helvetica Neue", sans-serif;
}
h1, h2, h3, h4, h5, h6, img { 
	page-break-after:avoid; 
	page-break-inside:avoid 
}
page {
	position: relative;
	outline: 0;
	overflow: hidden;
	display: block;

	height: 100%;
	margin: 0 auto;
	padding: 0;

	padding-top: 200px;
	padding-bottom: 150px;

	background-image: url(img/jj-marca-dagua.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 80%;
}
@page {
	orphans: 4;
	widows: 2;

	size: A4 landscape;
	margin: 0;
}
@page {
	orphans: 4;
	widows: 2;

	size: A4 portrait;
	margin: 0;
}
.page-break {
	page-break-before: always;
	page-break-after: always;
}

.page-header, .page-footer,
.page-header-space, .page-footer-space {
	height: 150px;
}

.page-header, .page-footer {
	position: fixed;
	width: 100%;
	left: 0;
	background-size: 100% auto;
	background-repeat: no-repeat;
	z-index: 100;
	padding: 0;
	font-weight: bold;
	color: #FFF;
}

.page-header .bg, .page-footer .bg {
	position: absolute;
	z-index: -1;
	width: 100%;
	height: auto;
}
.page-header {
	top:  0;
	display: flex;
	align-items: flex-start;
}

.page-header .logo {
	max-height: 100%;
}

.page-footer {
	bottom: 0;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.custom-table .expand-row .row-extra-data {
	height: auto !important;
	overflow: visible;
}

.img-thumbnail.col-4 {
	max-width: 25%;
}
.page-number {
	position: absolute;
	display: block;
	bottom: 30px;
	z-index: 999;
	right: 30px;
}
@media only screen and (orientation: landscape) {
	html, body {
		height: auto;
		background: rgb(204,204,204); 
	}
	page {
		display: block;
		width: 21cm;
		min-height: 29.7cm;
		max-height: 29.7cm;
		margin: 0cm auto;
	}
}

@media print {
	thead {display: table-header-group;} 
	tfoot {display: table-footer-group;}
	body {margin: 0;}

	page {
		width: initial !important;
		height: initial !important;
		margin: 0 !important;
		border: initial !important;
		border-radius: initial !important;
		box-shadow: initial !important;

		page-break-after: always;
		page-break-inside: avoid;
	}
}


/* ******************************************** */
.custom-table {
	min-width: 900px;
}

.custom-table > tbody th, .custom-table > tbody td {
	color: #777;
	font-weight: 400;
	padding-bottom: 20px;
	padding-top: 20px;
	font-weight: 300;
}

.custom-table tbody th small, .custom-table tbody td small {
	color: #b3b3b3;
	font-weight: 300;
}

.custom-table tbody tr:not(.expand-row) {
	border-radius: 7px;
	overflow: hidden;
	-webkit-transition: .3s all ease;
	-o-transition: .3s all ease;
	transition: .3s all ease;
}

.custom-table tbody tr:not(.expand-row):hover {
	-webkit-box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.1);
}

.custom-table tbody tr th, .custom-table tbody tr td {
	border: none;
	-webkit-transition: .3s all ease;
	-o-transition: .3s all ease;
	transition: .3s all ease;
}

.custom-table tbody tr th:first-child, .custom-table tbody tr td:first-child {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.custom-table tbody tr th:last-child, .custom-table tbody tr td:last-child {
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}
	.custom-table tbody tr.spacer td {
	padding: 0 !important;
	height: 3px;
	border-radius: 0 !important;
	background: transparent !important;
}

.custom-table .pi {
	font-size: 1em;
}

.custom-table .expand-row td {
	padding: 0;
	background-color: #f3f3f3;
}

.custom-table .expand-row .row-extra-data {
	height: 0;
	overflow: hidden;
	-webkit-transition: .3s all ease;
	-o-transition: .3s all ease;
	transition: .3s all ease;
}

.custom-table .expand-row .row-extra-data.active {
	height: auto;
	padding: 1em;
}
