* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #F4F5F6;
  color: #333;
  font-size: 14px !important;
}

/*********************************************************************/
/*********************************************************************/
/*********************************************************************/

.secondColorBK {
  background-color: #fff;
}

.logoImg {
  max-width: 50px;
}

.mainBackgroundGrad {
  background-image: linear-gradient(75deg, #E4D6FF, #F8FAFF,#C0CDFE);
}

.jjWhiteLogoImgBackground {
  background-image: url("./config/imgs/jj_white_logo.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.mainGradBK {
  background-image: linear-gradient(90deg, #2CA8FF, #5B7DFB);
}

.mainDegLine {
  width: 100%;
  height: 5px;
  border-radius: 7px 7px 0 0;
  background-image: linear-gradient(90deg, #2CA8FF, #5B7DFB);
}

.radius12 {
  border-radius: 12px;
}

.use100 {
  width: 100%;
  height: 100%;
}

.minUse100 {
  min-width: 100%;
  min-height: 100vh;
}

.successBk {
  background-color: #21CA90 !important;
}

.warningBk {
  background-color: #E4ED21 !important;
}

.errorBk {
  background-color: #EA3A1E !important;
}

.smallShadow {
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}


a, a:hover, a:focus, a:active {
  text-decoration: none !important;
  color: #344767 !important;
  font-size: 16px !important;
}

/* Person aliza o ScrollBar */
::-webkit-scrollbar {
  width: 7px;
  /* display: none; */
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  /* background: rgba(44, 168, 255,0.7);  */
  background-image: linear-gradient(75deg, #E4D6FF, #F8FAFF,#C0CDFE);
  -webkit-box-shadow: inset 1px 10px 0 rgba(0,0,0,0.2);
  box-shadow:0 1px 10px 0 rgba(0,0,0,0.2);
}

/* Só exibe esse component com resolução acima de 768px */
.notShowUntilMedium {
  /* display: none; */
}

@media only screen and (max-width: 768px) {
  .notShowUntilMedium {
    display: none;
  }
}

/*********************************************************************/
/*********************************************************************/
/*********************************************************************/

.full {
  width: 100%;
  min-height: 100vh
}

.full20 {
  width: 100%;
  min-height: 20vh
}

.full40 {
  width: 100%;
  min-height: 40vh
}

.full60 {
  width: 100%;
  min-height: 60vh
}

.full80 {
  width: 100%;
  min-height: 80vh
}

.fullContainer {
  width: 100%;
  min-height: 90vh
}

.center {
  display: flex;
  justify-content: center;
  align-items: center
}

.centerW{
  justify-content: center
}

.centerH{
  display: flex;
  align-items: center
}

.toRight {
  display: flex;
  justify-content: flex-end;
}

.toRight1{
  display: flex;
  justify-content: flex-end;
}

.whiteBackground {
  background-color: #fff;
}

.whiteFont {
  color: #fff !important
}

.blackFont {
  color: #000
}

.noDecoration:link {
  color: 'inherit';
  text-decoration: 'inherit'
}

.blueFont {
  color: #2CA8FF
}

.toRight {
  float: right;
}

/* .mainColorBk {
  background-color: #2CA8FF;
} */

.blackColorBk {
  background-color: #000;
}

.mainColorBK {
  background-color: #343434;
}

.inline{
  display: flex;
  flex-direction: row
}

.inlineRight{
  display: flex;
  justify-content: flex-end;
  color: aquamarine
}

.title {
  font-size: 1.7em;
  font-weight: bold;
}

.subTitle {
  font-size: 1.2em;
}

.padding03{
  padding: 3px
}

.padding04{
  padding: 4px
}

.padding08 {
  padding: 8px;
}

.padding10 {
  padding: 10px;
}

.padding20 {
  padding: 20px;
}

.padding30 {
  padding: 30px;
}

.padding20Side {
  padding-left: 20px;
  padding-right: 20px;
}

.padding5Side {
  padding-left: 5px;
  padding-right: 5px;
}

.padding50Side {
  padding-left: 50px;
  padding-right: 50px;
}

.padding50Top {
  padding-top: 50px;
}

.padding100Top {
  padding-top: 100px;
}

.margin2 {
  margin: 2px;
}

.margin5 {
  margin: 5px;
}

.margin10 {
  margin: 10px;
}

.topLess1 {
  margin-top: -1px
}

.top10 {
  margin-top: 10px
}

.top20 {
  margin-top: 20px
}

.top30 {
  margin-top: 30px
}

.top40 {
  margin-top: 40px
}

.top60 {
  margin-top: 60px
}

.top100 {
  margin-top: 100px
}

.bottom30 {
  margin-bottom: 30px
}

.sideSpace {
  margin-left: 2px;
  margin-right: 2px
}

.corr {
  background-color: aquamarine
}

/*
  Mascara para ficar sobre backgroundImgs, 
  assim deixa as imagens mais escuras
*/

.blackMask06{
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px
}

.blueMask06{
  background-color: rgba(44, 168, 255,0.6); 
  border-radius: 5px
}

.blueMask01{
  background-color: rgba(44, 168, 255,0.1); 
  border-radius: 5px
}

.redMask06{
  background-color: rgba(255, 0, 0,0.6); 
  border-radius: 5px
}

/*
  Botão com backgroundColor branco e fonte azul
*/
.btWhite {
  padding: 15px 50px 15px 50px;
  background-color: #fff;
  border-radius: 30px;
  border: none;
  color: #2CA8FF;
  transition: all 0.2s
}

.btWhite:hover {
  opacity: 0.7;
  cursor: pointer
}

/*
  Botão com backgroundColor Azul e fonte Branca
*/
.btBlue {
  padding: 15px 50px 15px 50px;
  background-color: #2CA8FF;
  border-radius: 30px;
  border: none;
  color: #fff;
  transition: all 0.2s
}

.btBlue:hover {
  opacity: 0.7;
  cursor: pointer;
  color: #fff;
}

.btBlueSmall {
  padding: 5px 18px 5px 18px;
  background-color: #2CA8FF;
  border-radius: 5px;
  border: none;
  color: #fff;
  transition: all 0.2s
}

.btBlueSmall:hover {
  opacity: 0.7;
  cursor: pointer;
  color: #fff;
}


/*
  Componente utiliza 100% do espaço em largura(Width),
  pode ser utilizado com qualquer div, bt...
*/
.w100 {
  width: 100%;
}

.w10 {
  width: 10px;
}

/*
  Texto alinhado a...
*/
.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.delay{ /* SEMPRE que ouver a troca de classes tendo essa classe tbm esse delay vai funcionar*/
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.delay2{
    -webkit-transition: all 0.7s ease;
    -moz-transition: all 0.7s ease;
    -o-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.menu_nav_bar{
    background-color: #343434;
    padding: 10px;
    border: solid #343434 1px;
    border-radius: 0px;
    font: #fafafa;
    /* opacity: 0.7; */
}

.invisible {
  display: none;
}

.notShow {
  visibility: hidden;
}


.menu_options{
    color: #fff;
    font-family: "Times New Roman", Times, serif;
    font-size: 20px;
}

span{
    /* color: #34477F; */
    color: #474747;
}

/* *************** */

.circulo {
  display: inline-block;
  width: 160px;
  height: 160px;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #666;
  border: 6px solid #199EB8;
  border-radius: 50%;
  padding: 55px 25px 0 25px;
  position: relative;
}

.circulo .detalhe {
  color: #fff;
  width: 70px;
  height: 70px;
  line-height: 70px;
  top: -25px;
  right: -15px;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-size: 18px;
  background-color: #E04F00;
  position: absolute;
  border-radius: 50%;
  font-weight: 700;
}

.circulo:hover {
  border: 6px solid tomato;
}

/* personaliza o botao do icone */
.icone_servico{
    background-color: #028fcc;
    width: 90px;
    height: 90px;
    color: white;
}
.icone_servico:HOVER{
    background-color: #2E2E2E;
}

/* Personaliza o icone */
.icone_servico_logo{
    color: white;
    font-size: 40px;
}

.mainTitle h1 {
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 30px;
}

.opacitybk03 {
  /* background-color: rgba(44, 168, 255,0.3);  */
  background-color: rgba(44, 168, 255,0.1); 
  border: none;
}
.borderNone {
  border: none;
}

.borderTopGray {
  border-top: 2px solid #d3d3d3;
}

.borderGray {
  border-bottom: 2px solid #d3d3d3;
}

.borderWhite {
  border: solid #fff 1px;
}

.borderRadius01 {
  border-radius: 1px;
}

.borderRadius02 {
  border-radius: 2px;
}

.borderRadius04 {
  border-radius: 4px;
}

.borderRadius10 {
  border-radius: 10px;
}

.errorBk {
  background-color: #A2250A;
  border: none;
  color: #fff;
}

.successBk {
  background-color: #04B45F;
  border: none;
  color: #fff;
}

.warningBk {
  background-color: #FFD933;
  border: none;
  color: #fff;
}

.topBlueBorder {
  border-top: solid #5B7DFB 1.5px;
}

.bottomBlueBorder {
  border-bottom: solid #2CA8FF 1.5px;
}

.iconBoxField{
  padding: 3px;
  font-size: 20px;
}

.margin1 {
  margin: 1px;
}

.margin2 {
  margin: 2px;
}

.marginRight7 {
  margin-right: 7px;
}

.lessTopMarge70 {
  margin-top: -70px;
}

.boxShadow {
  box-shadow:0 10px 16px 0 rgba(0,0,0,0.2)
}

.boxShadow:hover {
  box-shadow:0 15px 21px 0 rgba(0,0,0,0.2)
}

.boxShadowHover:hover {
  box-shadow:0 10px 16px 0 rgba(0,0,0,0.2);
  border-radius: 10px;
}

.disable {
  opacity: 0.3;
}

.positionRelative {
  position: relative;
}

.positionAbsolute {
  position: absolute;
}

.shadowCircle {
  border-radius: 100%;
  box-shadow:0 10px 16px 0 rgba(0,0,0,0.2);
  background-color: #666;
  color: #fff;
  width: 50px;
  height: 50px;
  padding: 15px;
  opacity: 0.6;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #667;
}

.noBorder {
  border: none
}

.fileUpload {
  border: 1px solid #ccc;
  width: 150px;
  height: 35px;
  cursor: pointer;
  background-color: #2CA8FF;
  border-radius: 10px;
  position: relative;
}

.fileUpload:hover {
  border: 1px solid #2CA8FF;
  background-color: #fff;
  color: #000;
}

.fileUploadInside {
  position: absolute;
  margin-top: 15px;
}

.fileUpload input[type="file"] {
  display: none;
}

.over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
}

.topFixed {
  position: fixed;
  top: 0px;
}

.logoImg {
  max-width: 50px;
}

.inBold {
  font-weight: bold;
}



/* 
gridjs-head
gridjs-wrapper
gridjs-footer
*/

.thgrid {
  padding: 15px !important;
  font-size: 1.1em;
  color: #B0BACA;
  font-weight: normal;
  border-bottom: solid 1px #E9ECEF !important;
}

.tdgrid {
  padding: 4px 0px 0px 8px !important;
  font-size: 1em;
  border-bottom: solid 1px #E9ECEF !important;
  color: #344767 !important;
}

/* Sobreescreve o CSS do GridJS */
.gridjs-head{
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.gridjs-wrapper{
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.gridjs-footer{
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

/*******************************************/