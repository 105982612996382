.floatingInput {
    width: 100%;
    border: 0;
    outline: 0;
    padding: 0.5rem 0;
    border-bottom: 2px solid #d3d3d3;
    box-shadow: none;
    color: #111;
}

.floatingInput:focus {
    width: 100%;
    border: 0;
    outline: 0;
    padding: 0.5rem 0;
    border-bottom: 2px solid #2CA8FF;
    box-shadow: none;
    color: #111;
}

.floatingInputRequired {
    border-bottom: 2px solid #A2250A;
}

.boxFloatingInput {
    position: relative;
}

.labelFloating {
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
    /* color: #d3d3d3; */
    color: #000;
    transition: 0.2s all;
    cursor: text;
}

.floatingInputOnFocus {
  font-size: 14px;
  top: -5px;
  color: #2CA8FF;
}