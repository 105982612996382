.inputOpacity{
  border: none;
  background-color: transparent;
  width: 100%;
  height: 30px;
  /* color: #fff; */
  color: #333;
}

.iconBox{
  padding: 3px;
  font-size: 20px
}

.inputBoxOpacity{
  border-radius: 7px;
  border: none;
  /* background-color: #42B0FE; */
  background-color: rgba(44, 168, 255,0.1); 
  width: 100%;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #fff;
}